/**
 * Created by elwhite on 8/12/20.
 */
import React from 'react'

import {Text, ImgSrc, Header, Row, ColumnContainer, Column, Spacer} from '../Components/Layout/Layout';
import {TitleHeader} from '../Components/Title/Title';
import {Blogs} from './Blogs/Blogs';
import style from "./pages.modules.css"

import womenWorking from '../Components/Imgs/womenWorking.svg'

export const BlogBreak = ({space}) => (
    <div style={{height: space, width: "100%", borderBottom: "1px solid lightgrey"}}></div>
);


export const BlogRow = ({title,href, short}) => (
   <>
   <a href={href}>
       <Row>
           <ColumnContainer>
               <Column>
                   <Header> {title} </Header>
               </Column>
               <Column>
                   <Text>{short}</Text>
               </Column>
           </ColumnContainer>
           <BlogBreak space="20px"/>
       </Row>
   </a>
   </>
    
);


export const Blog = () => (
    <div className={style.page}>
        <Row>
            <TitleHeader title="Welcome to Our Blog"
                         sideChildren={<ImgSrc src={womenWorking}/>}/>
            <Spacer space="30px"/>
        </Row>
        {
            Blogs.map( (blog) => {
                return <BlogRow title={blog.title} href={blog.href} short={blog.short}/>  
            })
        }
    </div>

);