/**
 * Created by elwhite on 3/28/19.
 */
import React from 'react';
import {CalanderContact} from '../Components/Contact/Contact';
import {Row, Text, Header} from '../Components/Layout/Layout';
import style from './pages.modules.css';


const ContactInfo = () => (
    <div className={style.contactInfo}>
        <Text>Phone: <a href='tel:+16502948825'>(650) 294-8825</a></Text>
        <Text>Email: <a href='mailto:dwhite@scpaloalto.com'> dwhite@scpaloalto.com</a></Text>
        <Text>2225 Bayshore Rd. Suite 200 Palo Alto, CA 94303</Text>
    </div>
);


export const Contact = () => (
    <div className={style.page}>
        <Row>
            <Header>Book a Meeting</Header>
            <Text>Learn more about our solutions and how they fit your goals</Text>
        </Row>
        <Row backgroundColor="#fbfcfd">
            <CalanderContact/>
        </Row>
        <Row header="Contact SC Palo Alto">
            {<ContactInfo/>}
        </Row>
    </div>
);
