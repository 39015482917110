/**
 * Created by elwhite on 4/22/20.
 */
import React from 'react';
import {Title, Text, Row} from '../Layout/Layout'
import styles from './title.module.css';



export const TitleHeader = ({title, slogan, text, sideChildren, children}) => (

    <Row>
        <div className={styles.titleHeader}>
            <div className={styles.title}>
                <Title>{title}</Title>
                <Text>{slogan}</Text>
                {children}
            </div>
            <div className={styles.side}>
                <Text>{text}</Text>
                {sideChildren}
            </div>
        </div>
    </Row>
);
