/**
 * Created by elwhite on 2/18/20.
 */
import React from 'react';
import {Row, Column, ImgSrc, BoldText, Spacer, Text, Quote} from '../Components/Layout/Layout';
import {ImgOverlay} from '../Components/ImgOverlay/ImgOverlay';
import {TitleHeader} from '../Components/Title/Title';
import style from './pages.modules.css';
import levels from '../Components/Imgs/levels.svg';

export const CaseStudies = () => (
    <div className={style.page}>
        <TitleHeader title="Case Studies" sideChildren={<ImgSrc src={levels}/>}/>
        <Row>
            <Column>
                <Text>With over 20 years of building Silicon Valley startups we have the credentials and track record to
                    help you build your team as well.
                </Text>
            </Column>
        </Row>
        <Row>
            <Column>
                <BoldText>Simplee - Health Tech Startup</BoldText>
                <Text>Needed to meet their revenue, profit and growth goals over the past 4 years while they built their
                    team here in Silicon Valley. This was made more complex as they developed new products and
                    implemented new growth strategies demanding different skill sets from their new hires.
                </Text>
                <Spacer space="12px"/>
                <Text>
                    SC Palo Alto implemented a 4-year program resulting in hiring 31 new employees that helped meet
                    company goals at all phases of growth. Currently 50% of their employees came through SC Palo Alto’s
                    efforts. Most recently the company was acquired due in part to the strong team they have built.
                </Text>
                <Spacer space="12px"/>
                <Quote>… [SC Palo Alto] provides quite a bit of value along with his service, each candidate was extremely
                    well vetted… [Their] industry experience was a huge value for us, and always had a plan for each
                    candidate and position that was open.</Quote>
                <Spacer space="12px"/>
                <Quote>… Every candidate has great things to say about Dave. He truly is able to be the trusted advisor,
                    not only to the candidates, but the hiring manager.</Quote>
                <Spacer space="12px"/>
                <Quote>…[SC Palo Alto] was a very collaborative partner throughout the recruiting process... [SC Palo
                    Alto] operated as a trusted advisor and part of the team every step of the way. I would highly
                    recommend David and his organization for any team looking to rapidly scale and bring on high quality
                    talent.</Quote>
            </Column>
        </Row>
        <Row>
            <Column>
                <BoldText>AutoGrid - Energy Tech Startup</BoldText>
                <Text>Needed to fill 22 critical roles in 6 months. Their management team was spending hours at night
                    pouring through their LinkedIn connections, sending messages and reviewing applicants that were not
                    a fit. End result was too much time spent only to end up empty handed with no real candidates and
                    missing hiring and business goals.
                </Text>
                <Spacer space="12px"/>
                <Text>
                    SC Palo Alto implemented an integrated recruiting program driving the hiring of all 22 new employees
                    on time. Managers no longer spent their evenings recruiting and filled their roles with SC Palo Alto
                    at a savings of over 25%.
                </Text>
            </Column>
        </Row>
        <Row>
            <Column>
                <BoldText>Arrayant - IoT Startup</BoldText>
                <Text>Needed to build out their team across all functions with a focus on engineers. This was vital to
                    bring vital new products to market on time. Hiring was key to meeting their business goals.
                </Text>
                <Spacer space="12px"/>
                <Text>
                    SC Palo Alto implemented a dedicated onsite program over a 2-year period hiring 18 new employees
                    across all functions. Company was acquired as planned.
                </Text>
            </Column>
        </Row>
        <Row>
            <Column>
                <BoldText>PlayFirst - Gaming Startup</BoldText>
                <Text>Needed to move away from dependence on PC games and into mobile and social games to remain
                    competitive and achieve revenue growth targets.
                </Text>
                <Spacer space="12px"/>
                <Text>
                    SC Palo Alto delivered a 3-year fully integrated recruiting program building out their entire mobile
                    and social teams at all levels. With their new team the company gained a major footprint and growth
                    in both mobile and social games resulting in a successful acquisition of the company.
                </Text>
            </Column>
        </Row>
    </div>
);