/**
 * Created by elwhite on 12/1/20.
 */
import {CeoCloseDeal} from "./CeoClosingDeals";
import {TeamFit} from "./TeamFit";
import {TeamFitVs} from "./TeamFitVs";
import {HireBestPeople} from "./HireBestPeople";
import {HireCTO} from './HireCTO';
import {AcceptedOffer} from './AcceptedOffer';

export const Blogs = [
    {
        title: "The CEO's role in Closing Your Top Candidates",
        href: "/blog/ceoClosingDeals",
        short: "Through the large conference room windows, you can see your CEO with your top candidate. Your candidate" +
        "has a fair offer and your CEO is “closing” the deal...",
        doc: CeoCloseDeal
    },
    {
        title: "Determining a Team Fit",
        href: "/blog/teamFit",
        short: "You are sitting across the table with a top candidate. She seems great! Friendly. High energy. " +
        "Lots of eye contact. Great demeanor. Seems very interested and prepared. Easy to talk with. You end the " +
        "interview feeling great. But, did you really get a feel for her culture and team fit?",
        doc: TeamFit
    },
    {
        title: "Team Fit vs. Personality",
        href: "/blog/teamFitVs",
        short: "Picture this: Your final video interview with a well qualified candidate is going well. Are you " +
        "missing anything? She seems great! Smart. High energy. Lots of eye contact. Well prepared and interested " +
        "for the right reasons. You end the interview feeling great. She is technically qualified. She might be the " +
        "one!  What about company and team cultural fit? ",
        doc: TeamFitVs
    },
    {
        title: "Hire the Best People",
        href: "/blog/hireBestPeople",
        short: '“As a leader of an early stage startup how do I know I am hiring the best people for us?” Consider ' +
        'these 4 factors:',
        doc: HireBestPeople
    },
    {
        title: "Do You Need to Hire a CTO?",
        href: "/blog/hireCTO",
        short: "I have heard “I need a CTO” many times from early stage startups. Do you need a CTO or would a well " +
        "qualified engineer or team lead fit your needs? Often times startups make the decision to hire a CTO in order " +
        "to pay in equity, saving cash. How you pay does not make the role a CTO. You need to hire the right skills for " +
        "what you need at this time.",
        doc: HireCTO
    },
    {
        title: "Accepted Offer, What Can Go Wrong?",
        href: "/blog/acceptedOffer",
        short: "Congratulations! Your top candidate accepted your offer and everything is in order. Or, is it? Has " +
        "anyone lost a candidate after they signed the offer? There is still time between the candidate signing the " +
        "offer and when they start.",
        doc: AcceptedOffer
    }
];