/**
 * Created by elwhite on 2/17/20.
 */
import React from 'react';
import {Calander} from '../Calander/Calander';
import {Text, BoldText} from '../Layout/Layout';
import style from './contact.module.css';
import linkedinIcon from '../Imgs/linkedin-logo.png';
import ericProfile from '../Imgs/eric.jpg';
import davidProfile from '../Imgs/david-profile.png';



const ContactImage = ({src}) => (
    <img  className={style.image} src={src} alt=""/>
);

const ContactName = ({name, linkedin}) => (
    <div className={style.contactName}>
        <BoldText>{name}</BoldText>
        {linkedin && <Linkedin href={linkedin}/>}
    </div>
);

const Linkedin = ({href}) => (
    <a href={href} target="_blank" rel="noopener noreferrer">
        <div className={style.linkedin}>
            <img src={linkedinIcon} alt=""/>
        </div>
    </a>
);


export const ContactCard = ({img, name, linkedin, children}) => (
    <div className={style.card}>
        <ContactImage src={img}/>
        <ContactName name={name} linkedin={linkedin}/>
        {children}
    </div>
);

export const DaveCard = ({children}) => (
    <ContactCard
        name="Dave White"
        linkedin="https://www.linkedin.com/in/davewhitescpaloalto/"
        img={davidProfile}>
        <Text>President and Founder</Text>
        {children}
    </ContactCard>
);

export const EricCard = ({children}) => (
    <ContactCard
        name="Eric White"
        linkedin="https://www.linkedin.com/in/ericwhite16/"
        img={ericProfile}>
        <Text>Technical Operations</Text>
        <Text>Account Executive</Text>
        {children}
    </ContactCard>
);

export const ContactColumn = () => {
    return (
        <div className={style.column}>
            <DaveCard/>
            <EricCard/>
        </div>
    )
};


export const CalanderContact = () => {
    return (
        <div className={style.calander}>
            <div className={style.calanderContact}>
                <ContactColumn/>
            </div>
            <div className={style.calanderCalander}>
                <Calander/>
            </div>
        </div>
    )
};
