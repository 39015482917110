/**
 * Created by elwhite on 3/26/19.
 */
import React, {useState, useContext, useEffect} from 'react';

import {WindowSizeContext, WindowSizes} from '../../App';

import styles from './layout.module.css';

export const Row = ({backgroundColor, txtColor, children}) => (
    <div className={styles.row}
         style={{
            backgroundColor: backgroundColor,
            color: txtColor
         }}>
        {children}
    </div>
);


export const Spacer = ({space}) => (
    <div style={{height: space, width: "100%"}}></div>
);


export const ImgSrc = ({src})=> (
    <img className={styles.imgSrc} src={src} alt="" />
);

export const Img = ({img, size, align}) => {
    return (
        <div style={{textAlign: align}}>
            <img className={styles.img}
                 alt=""
                 src={img.img}
                 style={{width: size}}/>
        </div>
    );
};

Img.defaultProps = {
    align: "center"
};


export const Column = ({children}) => {
    return (
        <div className={styles.column}>
            {children}
        </div>
    );
};

export const ColumnContainer = ({children}) => {
    const [flexDir, setFlexDir] = useState("row");
    const windowSize = useContext(WindowSizeContext);

    useEffect(() => {
        if (windowSize.width >= WindowSizes.lg) {
            setFlexDir("row")
        } else {
            setFlexDir("column")
        }
    },[windowSize]);

    return (
        <div className={styles.columnContainer} style={{flexDirection: flexDir}}>
            {children}
        </div>
    );
};

export const Title = ({children}) => (
    <h1 className={styles.title}>{children}</h1>
);

export const Header = ({children}) => (
    <h1 className={styles.header}> {children} </h1>
);

export const Text = ({children}) => (
    <p className={styles.text}> {children} </p>
);

export const BoldText = ({children}) => (
    <p className={styles.boldText}>{children}</p>
);

export const ImgIcon = ({src}) => (
    <img src={src} className={styles.icon} alt=""/>
);

export const Quote = ({children}) => (
    <span className={styles.quote}>
        <Text>"{children}"</Text>
    </span>
);


export const TextRow = ({children}) => (
    <div className={styles.textRow}>
        <TextColumnContainer>
            {React.Children.map(children, function(child){
                return (
                    <TextColumn>{child}</TextColumn>
                )
            })}
        </TextColumnContainer>
    </div>
);

export const TextColumnContainer = ({children}) => (
        <div className={styles.textColumnContainer}> {children} </div>
);

export const TextColumn = ({children}) => (
    <div className={styles.textColumn}> {children} </div>
);

export const Post = ({img, header, text}) => (
    <div className={styles.post} >
        <ImgPost src={img}/>
        <TextPost header={header} text={text}/>
    </div>
);

const ImgPost = ({src}) => (
    <img className={styles.imgPost} src={src} alt=""/>
);

const TextPost = ({header, text}) => (
    <div className={styles.textPost}>
        <BoldText>{header}</BoldText>
        <Text>{text}</Text>
    </div>
);

export const Card = ({img, header, text, href}) => (
    <div className={styles.card}>
        <a href={href}>
            <CardImg src={img}/>
            <CardText header={header} text={text}/>
        </a>
    </div>
);

const CardImg = ({src}) => (
    <img className={styles.cardImg} src={src} alt="" />
);

const CardText = ({header, text}) => (
    <div className={styles.cardText}>
        <BoldText>{header}</BoldText>
        <Spacer space="20px"/>
        <div className={styles.textAlignLeft}>
            <Text>{text}</Text>
        </div>
    </div>
);