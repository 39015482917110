/**
 * Created by elwhite
 */
// lib imports
import React from 'react';

// custom components
import {Button} from '../Components/Button/Button';
import {TitleHeader} from '../Components/Title/Title';
import {
    Row, Spacer, Column, ImgSrc, ColumnContainer,
    Header, BoldText, Text, Post, Card
} from '../Components/Layout/Layout';
import trendingUp from '../Components/Imgs/trending-up.svg';
import meditation from '../Components/Imgs/meditation.png';
import manage from '../Components/Imgs/manage.png';
import report from '../Components/Imgs/report.png';
import style from "./pages.modules.css";


//Imgs
import peopleStanding from '../Components/Imgs/standing2.svg'
import standingAtDesk from '../Components/Imgs/standingAtDesk.svg'
import peopleAtDesk from '../Components/Imgs/deskAnalytics2.svg'
import atDesk from '../Components/Imgs/atDesk1.svg'
import homeSVG from '../Components/Imgs/homeImg.svg'

export const HomePage = () => (
    <div style={style.page}>
        <TitleHeader title="SC Palo Alto" slogan="Recruiting for Silicon Valley Startups"
                     sideChildren={<ImgSrc src={homeSVG}/>}>
            <Button href="/contact">Book a Discovery Meeting</Button>
            <BoldText><a href="/about"> Our values &#x2192;</a></BoldText>
        </TitleHeader>
        <Row>
            <Header>Empower Your Hiring and Recruting</Header>
            <ColumnContainer>
                <Column>
                        <Card img={peopleStanding}
                              header="Start with Your Business Goals"
                              text="Let’s face it, the reason you are hiring is to meet business goals. A well-defined
                          set of business goals drive who you need to hire and how they will contribute to your
                          company’s success. "
                            href="/learning-center/start-with-your-goals"/>
                </Column>
                <Column>
                        <Card img={standingAtDesk}
                              header="Hire for Your Culture"
                              text="Hiring for cultural and team fit is critical to your success. Diversity and
                              inclusion is fundamental to building a great company."
                              href="/learning-center/hire-for-culture"/>
                </Column>
            </ColumnContainer>
            <ColumnContainer>
                <Column>
                        <Card img={peopleAtDesk}
                              header="Find the Best Candidates"
                              text="A comprehensive inbound and outbound recruiting program is required to find and hire the
                               best talent possible. Detailed planning and dedicated resources will elevate your success
                               while reducing cost and time."
                              href="/learning-center/find-best-candidates"/>
                </Column>
                <Column>
                        <Card img={atDesk}
                              header="Compete for Top Talent"
                              text="Finding top talent is one thing, landing top talent is another. Be prepared to compete
                              for the people you want on your team."
                              href="/learning-center/compete-for-talent"/>
                </Column>
            </ColumnContainer>
        </Row>
        <div style={{backgroundColor:"#f4f5f8"}}>
            <Row>
                <ColumnContainer>
                    <Column>
                        <ColumnContainer>
                            <Column>
                                <Header>Hiring for Early Stage Startups</Header>
                                <Text>Congratulations! You are growing a great new startup. You have raised your
                                    pre-seed, seed
                                    or A round of funding or landed your first client. Now you need to hire new
                                    employees and
                                    build your team. Whether you need to hire one person or a team knowing where to
                                    start, how
                                    to start and who to trust to help you are important factors as you work through
                                    these 4
                                    stages of hiring.</Text>
                            </Column>
                        </ColumnContainer>
                        <ColumnContainer>
                            <Column>
                                <BoldText><a href="/services#ESSProgram">Introducing Our Early Stage Startup
                                    Program &#x2192;</a></BoldText>
                            </Column>
                        </ColumnContainer>
                    </Column>
                    <Column>
                        <ColumnContainer>
                            <Column>
                                <BoldText>Getting Started</BoldText>
                                <Text>Let your business goals and culture drive your hiring</Text>
                            </Column>
                            <Column>
                                <BoldText>Analyze Costs, Save Time</BoldText>
                                <Text>Control costs and hire on time to meet your goals</Text>
                            </Column>
                        </ColumnContainer>
                        <ColumnContainer>
                            <Column>
                                <BoldText>Set Your Plan</BoldText>
                                <Text>Keep your team on the same page and on track with a detailed search process</Text>
                            </Column>
                            <Column>
                                <BoldText>Hire the Best Employees</BoldText>
                                <Text>Track your progess, engage candidates often, and know the winning offer</Text>
                            </Column>
                        </ColumnContainer>
                        <ColumnContainer>
                            <Column>
                                <BoldText><a href="/learning-center/hiring-early-stage-startups">Learn more about hiring
                                    in an Early Stage Startup &#x2192;
                                </a></BoldText>
                            </Column>
                        </ColumnContainer>
                    </Column>
                </ColumnContainer>
            </Row>
            <Row>
                <Column>
                    <Header>Creating Your Advisory Board</Header>
                    <Text>As a startup you need to have a highly valued Advisory Board. The right Board provide
                        insights, expertise, diversity, connections, leadership and a safe harbor for much needed
                        advice. Selecting the right Board members is as important as the people you hire.</Text>
                    <Spacer space="16px"/>
                    <Text>SC Palo Alto will help you find, evaluate and land key members of your Advisory Board. We
                        start by understanding what you need most from new members and then locate the people that will
                        have the best impact for you.</Text>
                    <BoldText><a href="/learning-center/create-advisory-board">Learn more about creating your advisory
                        board &#x2192;</a></BoldText>
                </Column>
            </Row>
        </div>
        <Row>
            <Header>Integrated Hiring Solutions</Header>
            <p style={{color: 'rgb(180, 123, 31)', margin:0}}>Hiring Does Not Have To Be So Hard</p>
            <Spacer space="10px"/>
            <Post img={trendingUp}
                  header="Meet your hiring goals"
                  text="Our dedicated professionals and resources help you meet your hiring and growth goals."/>
            <Post img={meditation}
                  header="Reduce the burden on your team"
                  text="We find the most qualified candidates so your management team can focus on meeting their
                  business goals."/>
            <Post img={manage}
                  header="Streamline and manage your hiring"
                  text="Effective management of your recruiting process is key to hiring the best candidates.  We deploy
                   the most efficient approach to building your team through all stages of growth."/>
            <Post img={report}
                  header="Build Trust through performance and transparency"
                  text="Trusting your recruiting process is key.  Back up your performance with quality candidates and
                  weekly progress reports to ensure you stay on track."/>
        </Row>
        <Row>
            <ColumnContainer>
                <Column>
                    <Header>Let's Get Started</Header>
                    <Text>We will start by talking about your business goals and specific hiring needs. From there we
                        can develop an approach and program that works best for you.
                    </Text>
                </Column>
                <Column>
                    <Button href="/contact">Book a Discovery Meeting</Button>
                </Column>
            </ColumnContainer>
        </Row>
    </div>
);