/**
 * Created by elwhite on 10/29/20.
 */

import React from 'react';
// custom components
import {
    Row,
    BoldText,
    Text,
    Column,
    Spacer,
    Header,

} from '../Components/Layout/Layout';

import styles from './pages.modules.css';

export const StartWithYourGoals = () => (
    <div className={styles.page}>
        <Row>
            <Column>
                <Header>Start with Your Business Goals</Header>
                <Spacer space="30px"/>
                <Text>
                    The reason you are hiring is to help you meet business goals. Before you write a job description or
                    look for candidates define the business goals this person will help youachieve. Will they help you
                    increase sales or market presence, bring new products to market on time, increase customer success,
                    reduce cost, or develop new expertise? The key is to define these goals and include them in your
                    hiring strategy and position description.
                </Text>
                <Spacer space="24px"/>
                <BoldText>Four Critical Steps:</BoldText>
                <Spacer space="16px"/>
                <Text>Ask yourself:</Text>
                <ul>
                    <li>How exactly will this person fit into the company and support your goals?</li>
                    <li>What will they do on a daily basis?</li>
                    <li>What attributes and skills must they have to fulfill your goals?</li>
                    <li>What attributes and skills can they learn once they are on the job?</li>
                    <li>How does this role align with both short-term and long-term goals?</li>
                    <li>How do I write a job description and candidate profile that aligns with our goals?</li>
                    <li>When do I need this person to ensure these goals are met?</li>
                    <li>When do I need this person to ensure these goals are met?</li>
                    <li>When do I need this person to ensure these goals are met?</li>
                </ul>
                <Text>Accurately define your time line: Take into account how long your recruiting and interview cycle
                    will take from start to offer. Add the time it takes for a person to accept your offer and their
                    actual start date. Include the time it will take for this person to come up speed and be productive.
                    This total time line may be longer than estimated and this can impact your ability to meet your
                    goals.</Text>
                <Spacer space="16px"/>
                <Text>Share your plan with all stakeholders including your business goals, job description and candidate
                    profile, interview plan, and timeframe. Gain their inputand approval prior to moving forward.</Text>
                <Text>Set your interview plan to ensure you and your team will ask questions that match candidate skills
                    and career aspirations to your business and team goals.</Text>
                <Spacer space="24px"/>
                <Text>This approach will allow you to: </Text>
                <ul>
                    <li>Meet your business goals and hiring timelines</li>
                    <li>Minimize changes and delays once the hiring process has started</li>
                    <li>Meet salary and cost estimates</li>
                    <li>Develop a consistent interviewing and hiring approach</li>
                    <li>Talking with the right candidates that are a good fit for you and are motivated by your company
                        and the job
                    </li>
                    <li>Hire the right people for your team</li>
                </ul>
                <Spacer space="24px"/>
                <BoldText>A note on long-term versus short-term goals</BoldText>
                <Text>Your long-term goals may be at odds with what you need to achieve in the short term. You may need
                    an approach for both long-term and short-term goals separately to make sure you have the best talent
                    for each requirement. Many early stage companies need to address this conflict on a routine basis.
                    For example, you may need a CTO to drive technology and strategy in the long term, but in the short
                    term you need to get your mobile app to market quickly. In this case, consider hiring your CTO in a
                    month or two and hire a mobile developer right away as an employee or possibly as a contractor. When
                    it comes to conflicting business needs, be creative and examine all of your options. These options
                    include full-time employees, part-time employees, contractors, consultants, interns, and using
                    existing internal resources.</Text>
                <Spacer space="24px"/>
                <BoldText>The risk of not hiring on time</BoldText>
                <Text>We have seen companies miss critical deadlines and goals because they did not bring in the key
                    talent they needed on time. They rick missing critical product releases, revenue and sales goals,
                    market expansion goals, customer support and retention requirements, and profitability and growth
                    goals. The key to minimizing this risk is in your planning right from the start. Work backwards to
                    develop your hiring timeline from the date your new employee needs to start contributing to your
                    goals. </Text>
            </Column>
        </Row>
    </div>
);