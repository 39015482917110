/**
 * Created by elwhite on 3/28/19.
 */
import React from 'react';
import {Row, Title, Header, Text, BoldText, Column, Spacer, ColumnContainer, Img} from '../Components/Layout/Layout';

import {buildTeams} from '../Components/Imgs/Imgs';
import highFive from '../Components/Imgs/highFive.svg'

export const Services = () => (
    <div>
        <Row>
            <Title>Customized Programs and Services</Title>
        </Row>
        <Row>
            <Column>
                <Header>Search and Hiring Programs</Header>
                <Text>We start by discussing your current goals, situation and hiring needs. We provide a view of the
                    landscape and determine your best approach to meeting your goals. From there we will develop a
                    custom solution that works best for you. We customize our search programs to meet your needs and
                    maximize our ability to perform for you.</Text>
            </Column>
        </Row>
        <Row><Column><Header>Our Customizable Programs</Header></Column></Row>
        <Row>
            <ColumnContainer>
                <Column>
                    <BoldText>On-site recruiting programs</BoldText>
                    <Text>Allow us to recruit and manage the entire staffing process from your facility as an integral
                        part of your team. Recommended when you have 3 or more critical positions to fill. </Text>
                    <Spacer space="12px"/>
                    <BoldText>Retained search</BoldText>
                    <Text>For executive level roles and your most critical positions</Text>
                    <Spacer space="12px"/>
                    <BoldText>Committed contingency search </BoldText> <Text>For individual open requirements that
                    require special focus and a dedicated recruiting approach.</Text>
                </Column>
                <Column>
                    <Img img={buildTeams} size="80%"/>
                </Column>
            </ColumnContainer>
        </Row>
        <div id="ESSProgram">
            <Row>
                <Column>
                    <Header>SC Palo Alto's Early Stage Startup Program</Header>
                    <Spacer space="30px"/>
                    <ColumnContainer>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <img src={highFive} alt="" style={{width: '100%', height: 'auto', maxWidth: '400px'}}/>
                        </div>
                        <Column>
                            <Text>Our Early Stage Startup Program is designed specifically for you, a growing startup. We
                                recognize your unique needs for hiring and building a cohesive, fast-paced and diverse team for your
                                early stage company. Our program accelerates your growth while working with us to ensure success
                                through all stages of startup hiring.gf</Text>
                            <Spacer space="16px"/>
                            <Text>Our approach allows us to deliver only what you need and addresses your need to control
                                cost at each stage of your hiring process. We deliver successful recruiting and
                                hiring programs that save you time and money while ensuring you hire the best employees
                                possible.</Text>
                        </Column>
                    </ColumnContainer>
                </Column>
            </Row>
        </div>
        <Row>
            <BoldText>
                <a href="/contact">Contact us &#x2192;</a> to see how these programs will satisfy your hiring goals
            </BoldText>
        </Row>
    </div>
);