/**
 * Created by elwhite on 10/30/20.
 */
import React from 'react';
// custom components
import {
    Row,
    Text,
    Column,
    Spacer,
    Header,

} from '../Components/Layout/Layout';

import styles from './pages.modules.css';

export const CreateAdvisoryBoard = () => (
    <div className={styles.page}>
        <Row>
            <Column>
                <Header>Creating Your Advisory Board</Header>
                <Spacer space="30px"/>
                <Text>As a startup you need to have a highly valued Advisory Board. The right Advisory Board provides:</Text>
                <ul>
                    <li>Unbiased insights and direction for your business and how to achieve your goals</li>
                    <li>Expertise you may not have yourself or on your leadership team</li>
                    <li>Diversity of experience, backgrounds, and critical thinking</li>
                    <li>Leadership guidance for you and your executive team</li>
                    <li>Increased focus on your top priorities and accountability to your stakeholders</li>
                    <li>Much needed feedback and personal development</li>
                    <li>Expansion of your connections, network, potential customers, partners and resources</li>
                    <li>A safe harbor for much needed advice, support and motivation when times are tough</li>
                </ul>
                <Spacer space="30px"/>
                <Text>To build the Advisory Board that is right for you talk with your investors, past coworkers and your
                    closest allies to see if they know of potential Board members. These referrals are an excellent way to
                    start. It is up to you to make sure they are the right fit for your company and each person brings something
                    unique and important to the table.</Text>
                <Spacer space="30px"/>
                <Text>If you have exhausted your network, SC Palo Alto will help you find, evaluate and land key members of your
                    Advisory Board. We start by understanding what you need most from new members and then locate the people
                    that will have the best impact for you.</Text>
            </Column>
        </Row>
    </div>
);