// lib imports
import React, {useState, useEffect, createContext, useContext} from 'react';
import {Route, Switch, BrowserRouter as Router, Redirect} from 'react-router-dom';
// custom components
import {NavBar} from './Components/NavBar/NavBar';
import {HomePage} from './Pages/Home'
import {About} from './Pages/About';
import {Contact} from './Pages/Contact';
import {Services} from './Pages/Services';
import {CaseStudies} from './Pages/CaseStudies';
import {Blog} from './Pages/Blog'
import {CeoCloseDeal} from './Pages/Blogs/CeoClosingDeals';
import {LearningCenter} from './Pages/LeraningCenter';
import {StartWithYourGoals} from './Pages/StartWithYourBusinessGoals';
import {HireForCulture} from './Pages/HireForCulture';
import {FindBestCandidates} from './Pages/FindBestCandidates';
import {CompeteForTalent} from './Pages/CompeteForTalent'
import {HiringESS} from "./Pages/HiringESS";
import {CreateAdvisoryBoard} from './Pages/CreateAdvisoryBoard';
import {Footer} from './Components/Footer/Footer';
import {Blogs} from './Pages/Blogs/Blogs';


//content
import style from './app.module.css';

const RedirectRoot = () => (
    <Redirect to="/home"/>
);

export const WindowSizes = {
    lg: 1080,
    sm: 280
};

export const WindowSizeContext = createContext({
    height: window.innerHeight,
    width: window.innerWidth
});


const WindowSizeProvider = ({children}) => {
    const [windowSize, setWindowSize] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    });
    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                height: window.innerHeight,
                width: window.innerWidth
            })
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, []);
    return (
        <WindowSizeContext.Provider value={windowSize}>
            {children}
        </WindowSizeContext.Provider>
    )
};

export const withWindowSize = WrappedComponent => props => {
    const windowSize = useContext(WindowSizeContext);
    return (
        <WrappedComponent {...props} windowSize={windowSize}/>
    )
};


const App = () => {
    return (
        <Router>
            <WindowSizeProvider>
                <div className="App">
                    <NavBar />
                    <div className={style.pageContainer}>
                        <div className={style.page}>
                            <Switch>
                                <Route exact path="/" render={RedirectRoot}/>
                                <Route path="/home" component={HomePage}/>
                                <Route path="/about" component={About}/>
                                <Route path="/contact" component={Contact}/>
                                <Route path="/services" component={Services}/>
                                <Route path="/case-studies" component={CaseStudies}/>
                                {
                                    Blogs.map( (blog) => {
                                        return <Route path={blog.href} component={blog.doc}/>
                                    })
                                }
                                <Route path="/blog" component={Blog}/>
                                <Route path="/learning-center/start-with-your-goals" component={StartWithYourGoals}/>
                                <Route path="/learning-center/hire-for-culture" component={HireForCulture}/>
                                <Route path="/learning-center/find-best-candidates" component={FindBestCandidates}/>
                                <Route path="/learning-center/compete-for-talent" component={CompeteForTalent}/>
                                <Route path="/learning-center/hiring-early-stage-startups" component={HiringESS}/>
                                <Route path="/learning-center/create-advisory-board" component={CreateAdvisoryBoard}/>
                                <Route path="/learning-center" component={LearningCenter}/>
                            </Switch>
                        </div>
                    </div>
                    <Footer />

                </div>
            </WindowSizeProvider>
        </Router>
    );
};

export default App;
