/**
 * Created by elwhite on 3/27/19.
 */
import React from 'react';
import style from './footer.module.css';

export const Footer = () => {
    return (
        <div style={style.push}>
            <div className={style.footer}> <p>&copy; 2020 SC Palo Alto.</p></div>
        </div>
    );
};
