/**
 * Created by elwhite on 10/29/20.
 */
import React from 'react';
// custom components
import {
    Row,
    BoldText,
    Text,
    Column,
    Spacer,
    Header,

} from '../Components/Layout/Layout';

import styles from './pages.modules.css';


export const HireForCulture = () => (
    <div className={styles.page}>
        <Row>
            <Column>
                <Header>Hire for Your Culture</Header>
                <Spacer space="30px"/>
                <Text>Hiring for cultural and team fit is critical. A candidate that has the technical skills and the
                    right background may not be your best choice and can actually be destructive to your team. Take time
                    to understand your current culture and the culture you are building. Develop guidelines for how
                    potential employees will fit andhelp you build your culture.</Text>
                <Spacer space="30px"/>
                <Text>Cultural aspects include:</Text>
                <div style={{margin: '0 0 0 40px'}}>
                    <Text>approach to teamwork, problem solving, risk taking, ability to work independently, management
                        structure and style, multi-tasking, work pace, trust, empathy, communication style, continuous
                        learning, detail versus big picture and the like.</Text>
                </div>
                <Spacer space="30px"/>
                <Text>So, how do you evaluate a candidate for cultural and team fit?</Text>
                <ul>
                    <li>Develop and document your team’s thoughts on cultural fit for new employees</li>
                    <li>Develop a plan with your interview team on how to interview for culture fit</li>
                    <li>Design situational and interactive interviews including team presentations and brainstorming
                        sessions
                    </li>
                    <li>Ask questions about how the candidate has handled real-life situations including times they felt
                        most appreciated in their work, times they faced a team challenge and how they approached it,
                        and a time they were at odds with team members and how they worked towards solutions
                    </li>
                    <li>Ask candidates to describe the work environment in which they perform best</li>
                    <li>Describe your culture and see how they respond</li>
                    <li>Provide an interview feedback approach that includes evaluating cultural fit as a team</li>
                    <li>Include cultural fit questions during reference checks for your final candidates</li>
                </ul>
                <Spacer space="30px"/>
                <BoldText>A potential red flag</BoldText>
                <Text>If your candidate complains or talks negatively about previous managers or team members this is a
                    situation you need to exploring further before moving forward. It may point to larger cultural fit
                    issue that you do not want to bring into your team.</Text>
                <Spacer space="30px"/>
                <BoldText>Diversity and inclusion as a part of your culture</BoldText>
                <Text>Companies that value diversity and inclusion and strives to build diverse teams will have the
                    highest levels of team morale, productivity, loyalty, retention and commitment. These companies have
                    the best ability to attract top talent while having a broader reach into more sources of candidates
                    from recent graduates to the most seasoned professionals. These inclusive companies have:</Text>
                <ul>
                    <li>Greater employee engagement</li>
                    <li>Higher levels of employee referrals for new employees</li>
                    <li>Lower employee turnover rates leading to reduced hiring and recruiting costs</li>
                    <li>Better company reputations</li>
                    <li>Better productivity and creativity</li>
                    <li>Greater ability to attract top talent</li>
                    <li>Greater ability to attract top talent</li>
                </ul>
                <Spacer space="30px"/>
                <Text>Actions you can take to increase diverse hiring: </Text>
                <ul>
                    <li>Ensure your career pages and job descriptions are written for maximum diversity and inclusion
                    </li>
                    <li>Post your job openings in varied and diverse job sites</li>
                    <li>Train your team on hiring, evaluating and interviewing diverse candidates</li>
                    <li>Make a strong statement about your diversity hiring on your website and within your job
                        descriptions
                    </li>
                    <li>Recruit from high diversity colleges and regions</li>
                </ul>
            </Column>
        </Row>
    </div>
);