// lib imports
import React from 'react';
// custom components
import {
    Row,
    Text,
    Column,
    Spacer,
    Header,

} from '../../Components/Layout/Layout';

import style from '../pages.modules.css';

export const AcceptedOffer = () => (
    <div className={style.page}>
        <Row>
            <Column>
                <Header>Accepted Offer, What Can Go Wrong?</Header>
                <Spacer space="30px"/>
                <Text>
                    Congratulations! Your top candidate accepted your offer and everything is in order. Or, is it? Has
                    anyone lost a candidate after they signed the offer? There is still time between the candidate
                    signing the offer and when they start.
                </Text>
                <Spacer space="20px"/>
                <Text>What can go wrong?</Text>
                <ul>
                    <li>Their current employer presents tempting counter offers</li>
                    <li>
                        They receive calls for other opportunities
                    </li>
                    <li>They are having second thoughs</li>
                </ul>
                <Spacer space="20px"/>
                <Text>A day or so before you present your offer call:</Text>
                <ul>
                    <li>Ask about their thoughts, motivations, and feelings about the role</li>
                    <li>Ask about other opportunities they have</li>
                    <li>Disscus the possibility of a counter offer from their current employer</li>
                    <li>Describe your offer process and what happens once they accept</li>
                </ul>
                <Spacer space="20px"/>
                <Text>Five critical steps once your candidate accepts your offer:</Text>
                <ul>
                    <li>Call them two to three times a week to check in and get to know them better</li>
                    <li>Discuss the equipment workplace setup they need</li>
                    <li>Discuss on-boarding, send a plan including agendas and names of coworkers</li>
                    <li>Have employees reach out by phone, email, and LinkedIn</li>
                    <li>Send benefits information, news, and product and market information (be clear reviewing this is
                        voluntary)</li>
                    <li>Keep in touch until they start</li>
                </ul>
                <Spacer space="20px"/>
                <Text>
                    These are good practices for all candidates while reducing risk and increasing your new employee’s 
                    enthusiasm and commitment to your team.
                </Text>
      
            </Column>
        </Row>
    </div>
);