/**
 * Created by elwhite on 3/24/19.
 */
import React , { useState, useContext } from 'react';
import {withRouter} from 'react-router-dom';
import {Button} from '../Button/Button';
import { WindowSizeContext, WindowSizes } from '../../App';
import classNames from 'classnames';
import logo from '../Imgs/logo.png';

import {expandIcon} from '../Imgs/Imgs';
import linkedinIcon from '../Imgs/linkedin-logo.png';
import styles from './navbar.module.css';


const NavListContent = [
    {txt: 'Home', path: '/home'},
    {txt: "Services", path: '/services'},
    {txt: "Case Studies", path: '/case-studies'},
    {txt: "Blog", path: "/blog"},
    {txt: "Learning Center", path: "/learning-center"},
    {txt: 'About', path: '/about'},
    {txt: 'Contact', path: '/contact'},
];


const NavItem = ({children}) => (
    <li className={styles.navItem}>
            {children}
    </li>
);

const LinkedinLogo = () => (
    <div className={styles.linkedinLogo}>
        <a href="https://www.linkedin.com/company/sc-palo-alto" target="_blank" rel="noopener noreferrer">
            <img src={linkedinIcon} alt="" />
        </a>
    </div>
);


const NavSelector = withRouter(({children, href, location}) => (
    <a  href={href}
        className={classNames(
                       styles.itemSelector,
                       {[styles.selected]: location.pathname === href}
        )}
    >
        {children}
    </a>
));

const NavImgBtn = ({img, onClick}) => (
    <li className={styles.navItem}>
        <div className={styles.imgContainer} onClick={onClick}>
            <img src={img.img} onClick={onClick} alt=""/>
        </div>
    </li>
);


const NavItemSM = ({children}) => (
    <li className={styles.navItemSM}>
        {children}
    </li>
);

const NavLogo = ({header, slogan, img}) => (
    <div className={styles.navLogo}>
        <a href="/" className={styles.logoSelector}>
            {img && <img src={img} className={styles.logoImg} alt=""/> }
            {header && <div className={styles.logoHeader}>{header}</div>}
            {slogan && <div className={styles.logoSlogan}>{slogan}</div>}
        </a>
    </div>
);


//items prop is an object with txtname: pathname key pairs
//items in items list appear in backwards order
const NavList = ({children}) => (
    <ul className={styles.navList}>
        {children}
    </ul>
);


const NavListSM = ({show, children}) => (
    <ul className={styles.navListSM}>
        {children}
    </ul>
);

// A self contained navbar without a dropdown menu
const NavBarLG = () => (
        <div className={styles.navBar}>
            <NavLogo header="SC Palo Alto" img={logo}/>
            <NavList>
                {NavListContent.map((item, idx) => (
                    <NavItem key={idx.toString()}>
                        <NavSelector href={item.path}>{item.txt}</NavSelector>
                    </NavItem>
                ))}
                <NavItem>
                    <Button href="/contact">Get Started !</Button>
                </NavItem>
                <NavItem>
                    <LinkedinLogo/>
                </NavItem>
            </NavList>
        </div>
);

// a navbar with a dropdown menu
const NavBarSM = () => {
    const [show, setShow] = useState(null);
    
    function toggleMenu() {
        setShow(!show)
    }

    return (

        <div>
            <div className={styles.navBar}>
                <NavLogo header="SC Palo Alto" img={logo}/>
                <NavList>
                    <NavImgBtn img={expandIcon} onClick={toggleMenu}/>
                </NavList>
            </div>
            {show &&
            <NavListSM>
                {NavListContent.map((item, idx) => (
                    <NavItemSM key={idx.toString()}>
                        <NavSelector href={item.path}>{item.txt}</NavSelector>
                    </NavItemSM>
                ))}
                <NavItemSM>
                    <Button href="/contact"> Get Started !</Button>
                </NavItemSM>
            </NavListSM>
            }
        </div>
    )
};


export const NavBar = () => {
    const windowSize = useContext(WindowSizeContext);
    if(windowSize.width > WindowSizes.lg) return (<NavBarLG/>);
    else return (<NavBarSM/>);
};


