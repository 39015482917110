// lib imports
import React from 'react';
// custom components
import {
    Row,
    Text,
    Column,
    Spacer,
    Header,

} from '../../Components/Layout/Layout';

import style from '../pages.modules.css';

export const TeamFitVs = () => (
    <div className={style.page}>
        <Row>
            <Column>
                <Header>Team Fit vs. Personality</Header>
                <Spacer space="30px"/>
                <Text>
                    Picture this: Your final video interview with a well qualified candidate is going well. Are you
                    missing anything? She seems great! Smart. High energy. Lots of eye contact. Well prepared and
                    interested for the right reasons. You end the interview feeling great. She is technically qualified.
                    She might be the one!
                </Text>
                <Spacer space="20px"/>
                <Text>
                    What about company and team cultural fit? Interviewing for culture fit so critical for your startup.
                    Every new member must be well qualified AND a fit for your culture.
                </Text>
                <Spacer space="20px"/>
                <Text>
                   Try the following:
                </Text>
                <Spacer space="20px"/>
                <ol>
                    <li>Start by truly understanding your team culture and dynamics. Know what you want it to be and
                        hire accordingly.</li>
                    <li>Develop real-life interview questions that reflect your culture:</li>
                    <ul>
                        <li>Teamwork and collaboration</li>
                        <li>Problem and conflict resolution</li>
                        <li>Risk taking</li>
                        <li>Trust</li>
                        <li>Creativity</li>
                        <li>Open to diversity and inclusion</li>
                        <li>Ability to speak up</li>
                        <li>Fast paced, methodical, or both</li>
                    </ul>
                    <li>Have multiple interviewers ask real life, team fit questions.</li>
                    <li>Create live scenarios or interactive presentations with multiple interviewers in the room. Look
                        for group dynamics and individual interactions.</li>
                    <li>Ask them about the workplace culture they seek.</li>
                    <li>Describe your culture, look for reactions and ask for their thoughts.</li>
                </ol>
            </Column>
        </Row>
    </div>
);
