/**
 * Created by elwhite on 10/30/20.
 */
import React from 'react';
// custom components
import {
    Row,
    Text,
    Column,
    Spacer,
    Header,

} from '../Components/Layout/Layout';

import styles from './pages.modules.css';

export const FindBestCandidates = () => (
    <div className={styles.page}>
        <Row>
            <Column>
                <Header>Finding the Best Candidates</Header>
                <Spacer space="30px"/>
                <Text>A comprehensive inbound and outbound recruiting program is required to find and hire the best
                    talent possible. Detailed planning and dedicated resources will elevateyour success while reducing
                    cost and time. You need to know who you are looking for and the skills and attributes they will need
                    to have. A well documented list of qualifications prior to starting your search allows you to
                    evaluate each candidate thoroughly and consistently. Finding people you need to hire requires a
                    well-planned program of inbound and outbound recruiting activities</Text>
                <Spacer space="30px"/>
                <Text>Inbound activities rely on potential candidates finding your company and job postings and actively
                    applying. These activities include:</Text>
                <ul>
                    <li>Post the job to your website and job boards</li>
                    <li>Promote your job through LinkedIn and social media</li>
                    <li>Promote your job through LinkedIn and social media</li>
                    <li>Follow up with non-qualified applicants by email</li>
                </ul>
                <Spacer space="30px"/>
                <Text>Outbound recruiting is a critical component to reaching the top candidates. These candidates may
                    not be actively looking for jobs or may not see your posting. An introductory conversation with
                    these candidates can lead to winning a top candidate. In addition, many of these people know and can
                    refer other top people toyou. Outbound activities include:</Text>
                <ul>
                    <li>Communicate with your network and people you know to ask for referrals</li>
                    <li>Have your employees communicate with their networks and people they know</li>
                    <li>Call potential candidates that do not know you to present your opportunity</li>
                    <li>Search LinkedIn and other job boards to find potentially qualified candidates</li>
                </ul>
                <Spacer space="30px"/>
                <Text>A thorough outbound recruiting program can be time consuming. Having a detailedplan is important.
                    For each role plan for 1 to 3 hours a day for a period of 4 to 8 weeks to find, analyze and
                    interview qualified candidates. Key factors for a well-constructed plan:</Text>
                <ul>
                    <li>Develop a process for your search to ensure consistency</li>
                    <li>Track your activity daily to measure progress and tune your approach</li>
                    <li>Evaluate candidates as much as possible during your first call to save time and keep your
                        process moving forward
                    </li>
                    <li>Be sure to ask about “blockers” such as salary, commute, work authority early in the
                        conversation
                    </li>
                    <li>Minimize your time spent pursuing non-qualified candidates, say “no” early tomaximize your time
                        with your top candidates
                    </li>
                    <li>Have a written interview form to evaluate skills, education, background, success and cultural
                        fit
                    </li>
                    <li>Follow up with all candidates to maintain good relationships</li>
                </ul>
                <Spacer space="30px"/>
                <Text>Track your activity daily to measure progress and tune your approachEvaluate candidates as much
                    as possible during your first call to save time and keep your process moving forwardBe sure to ask
                    about “blockers” such as salary, commute, work authority early in the conversationMinimize your
                    time spent pursuing non-qualified candidates, say “no” early tomaximize your time with your top
                    candidates Have a written interview form to evaluate skills, education, background, success and
                    cultural fitFollow up with all candidates to maintain good relationshipsIn particular, startups
                    tend to struggle the most with finding the right people early in the process. This is due to a lack
                    of resources and funds that can be dedicated to recruiting and search. This can lead to hiring
                    managers and executives spending too much time in efforts that lead to limited hiring results. We
                    have seen hiring managers working very late at night as they search through LinkedIn hoping to find
                    the talent they need. This takes time away from other areas of work and time away from family with
                    minimal results.</Text>
                <Spacer space="30px"/>
                <Text>It is important to find resources that can dedicate the time to develop and implement an effective
                    recruiting program within your budget.</Text>
            </Column>
        </Row>
    </div>
);