/**
 * Created by elwhite on 8/12/20.
 */
// lib imports
import React from 'react';
// custom components
import {
    Row,
    Text,
    Column,
    Spacer,
    Header,

} from '../../Components/Layout/Layout';

import style from '../pages.modules.css';

const title = "The CEO's role in Closing Your Top Candidates";

export const CeoCloseDeal = () => (
    <div className={style.page}>
        <Row>
            <Column>
                <Header>{title}</Header>
                <Spacer space="30px"/>
                <Text>
                    Through the large conference room windows, you can see your CEO with your top candidate. Your
                    candidate
                    has a fair offer and your CEO is “closing” the deal. This is not some hard sales pitch. Both look
                    relaxed
                    and seem to be enjoying the conversation. Your CEO is animated and confident as she talks about the
                    direction ofthe company, the culture and her vision of the future. She paints a picture that
                    includes the
                    candidate and the role he will play. The “close” in this case is a sharing of information and a
                    friendly and
                    confident invitation to join the company while also ensuring there are no last-minute red flags for
                    either
                    person. It looks like they are wrapping up and sharing a laugh. They end the meeting with your
                    candidate
                    signingthe offer and shaking hands. You, of course, go in to congratulate the candidate andtalk
                    about your
                    onboarding process.
                </Text>
                <Spacer space="20px"/>
                <Text>
                    When it comes to making a final close of a top candidate a meeting or call with the
                    CEO can be critical. It is easy to underestimate the role a startup CEO plays in this final step.
                    For a
                    candidate this might be their most important factor in accepting your offer. Candidates need to know
                    who is
                    driving the action, vision and culture of the company. Top hiring teams know this and plan for it
                    ahead of
                    time. Yet, great startups have lost top talent when the CEO of another company makes the final call
                    and
                    close.In many cases the CEO of a startup has met the candidate earlier in the interview process. In
                    this
                    case they have established a relationship making the closing meeting friendlier and more productive.
                </Text>
                <Spacer space="20px"/>
                <Text>
                    What makes a CEO an excellent closer? They...
                </Text>
                <ul>
                    <li>Are well prepared and know the candidate and role well.</li>
                    <li>Have the offer with them or available online for immediate signing if possible.</li>
                    <li>Are genuine and open about the company and the role. They paint a realistic picture of the
                        upside and
                        the challenges the company faces and provide the balance between the two. Afterall, you do not
                        want to
                        hire a person that does not see the upside or is not up for the challenges you face.
                    </li>
                    <li>Express their passion for the company, the people, and the mission.</li>
                    <li>Share their vision and a compelling picture of the future.</li>
                    <li>Are realistic and down to earth</li>
                    <li>Talk about the candidate’s role in achieving their vision</li>
                    <li>Listen to the candidate and ask questions of them to make sure they have covered the areas
                        important to
                        them.
                    </li>
                    <li>Asks for their decision (optional based on call setup)</li>
                    <li>Close the conversation on a positive note and friendly note</li>
                </ul>
                <Text>
                    Face-to-face meetings are most effective, but a video or phone call can work when timing is
                    critical. In
                    most cases it is best to schedule ahead of time, however, there are times an unexpected call from
                    the CEO is
                    quite powerful. Know which is right is based on the candidate, the role and the timing of the
                    decision
                    needed.
                </Text>
                <Spacer space="20px"/>
                <Text>
                    So, what do you do once your candidate has met your CEO? You need to move quickly to these next
                    steps.
                </Text>
                <Spacer space="20px"/>
                <Text>
                    Debrief with the CEO right away:
                </Text>
                <ul>
                    <li>Review how the meeting went, were there any concerns from the candidate?</li>
                    <li>Did the candidate sign or commit to accepting the offer or did they hedge? Has she/he signed the
                        offer?
                        This should happen right away if the candidate committed.
                    </li>
                    <li>Does the CEO have concerns about the candidate?</li>
                </ul>
                <Text>
                    Meet or call the candidate within an hour of the CEO's call.
                </Text>
                <ul>
                    <li>Get their feedback.</li>
                    <li>Provide the CEO’s feedback.</li>
                    <li>If they have not signed the offer yet, gain their commitment to when they willaccept the offer
                        or find
                        out why they cannot commit.
                    </li>
                    <li>Congratulate the candidate if they accept and get a commitment to send the signed offer right
                        away; any
                        delay is worrisome.
                    </li>
                    <li>If the candidate does not commit during this call, confirm your time frame for receiving a
                        signed offer.
                        Listen for reservations and address quickly if possible.
                    </li>
                </ul>
                <Text>
                    Do not stop recruiting until a signed offer is in hand. If the candidate hesitates you will be glad
                    you have
                    kept this as an active search.
                </Text>
                <Spacer space="20px"/>
                <Text>What if the CEO call does not go well and your CEO has reservations about your candidate? The
                    candidate
                    has your offer! Now what?</Text>
                <ul>
                    <li>Recognize this is stressful.</li>
                    <li>Determine the cause of her/his reservations? Can it be addressed with the candidate or your
                        on-boarding
                        plan?
                    </li>
                    <li>If it can be addressed create a plan in writing and share discuss it with the candidate. Frame
                        it in the
                        most productive way possible. Work through it withthe candidate and your CEO. Gain their
                        agreement.
                    </li>
                    <li>Modify the offer if needed.</li>
                    <li>If needed, set a new call with the CEO and the candidate to address the situation.</li>
                    <li>As a last resort, rescind the offer explaining the reasons and how this situation will not be
                        good for
                        the candidate either at this point.
                    </li>
                </ul>
                <Text>
                    The CEO call is a two-edged sword. Do you make the offer before or after the meeting? It is most
                    powerful if
                    the candidate has the offer and sometimes the candidate requests a meeting with the CEO after
                    receiving the
                    offer. Know that this meeting has this risk. There is less risk if the CEO has previously
                    interviewed the
                    candidate as part of the interview team. I you believe the CEO will close your candidate have the
                    CEO
                    interview your candidate earlier in the process.
                </Text>
                <Spacer space="20px"/>

                <Text>
                    Key – tell your candidate not to resign their current position until they have a signedoffer from
                    both
                    parties.
                </Text>
            </Column>
        </Row>
    </div>
);
