/**
 * Created by elwhite on 3/26/19.
 */
import expandIconImg from './expand-icon.png';
import buildTeamsImg from './buildteams.png';
import SFLandscapeImgCrop from './sflandscape-crop.jpeg';


export const SFLandscape = {
    img: SFLandscapeImgCrop,
    width: 4284,
    height: 830
};

export const expandIcon = {
    img: expandIconImg,
    width: 72,
    height: 72
};

export const buildTeams = {
    img: buildTeamsImg,
    width: 686,
    height: 409
};

