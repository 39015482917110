// lib imports
import React from 'react';
// custom components
import {
    Row,
    Text,
    Column,
    Spacer,
    Header,

} from '../../Components/Layout/Layout';

import style from '../pages.modules.css';

export const HireBestPeople = () => (
    <div className={style.page}>
        <Row>
            <Column>
                <Header>Hire the Best People</Header>
                <Spacer space="30px"/>
                <Text>
                    “As a leader of an early stage startup how do I know I am hiring the best people for us?” 
                </Text>
                <Text>Consider these 4 factors:</Text>
                <ol>
                    <li>Culture: A good fit helps you build and drive your culture. A mismatch can tear your culture
                        apart and terminating people for cultural fit can be difficult.</li>
                    <li>Passion: Does this person have passion for what you are doing and the role? Do they express
                        their passion?</li>
                    <li>Skills: Does this person have the skills you need for the job? Can they learn quickly?</li>
                    <li>Growth: Will this person have a growth path as your company grows? This is key to retain the
                        best people. Challenge them and build from within for the long term.</li>
                </ol>
                <Spacer space="20px"/>
                <Text>
                    A word on hiring for cultural fit and diversity: cultural fit does not mean everyone is the same.
                    Hiring people from diverse backgrounds and also support your vision and culture brings important
                    diverse points of view and experience to your team. Building the right culture addresses areas of
                    honesty, work ethic, risk taking, innovation, drive, adapting to change and the like.
                </Text>
                <Spacer space="20px"/>
                <Text>
                    You want to work with a partner that understands you as an early stage startup, will carefully 
                    listen to your business goals, situation, culture and your precise hiring criteria. Incorporating 
                    this into your recruiting process will build the best team possible.
                </Text>
                <Spacer space="20px"/>
            </Column>
        </Row>
    </div>
);