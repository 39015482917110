/*
 * Created by elwhite on 8/12/20.
 */
import React from 'react';

import {Text, Title, ImgSrc, Header, Row, ColumnContainer, Column, Spacer} from '../Components/Layout/Layout';
import {TitleHeader} from "../Components/Title/Title";
import styles from "./pages.modules.css";

import deskAnalytics from '../Components/Imgs/deskAnalytics1.svg'

export const BlogBreak = ({space}) => (
    <div style={{height: space, width: "100%", borderBottom: "1px solid lightgrey"}}></div>
);

const Indent = ({children}) => (
    <div style={{margin: "0 0 0 60px"}}>{children}</div>
);


export const LearningCenter = () => (
    <div className={styles.page}>
        <Row>
            <TitleHeader title="Welcome to the"
                         sideChildren={<ImgSrc src={deskAnalytics}/>}>
                <Title>Learning Center</Title>
            </TitleHeader>
        </Row>
        <Row>
            <Column>
                <h3>Empower Your Hiring and Recruiting</h3>
            </Column>
        </Row>
        <Indent>
            <a href="/learning-center/start-with-your-goals">
                <Row>
                    <ColumnContainer>
                        <Column>
                            <h3>
                                Start with Your Business Goals
                            </h3>
                        </Column>
                        <Column>
                            <Text>
                                Let’s face it, the reason you are hiring is to meet business goals. A well-defined set
                                of business goals drive who you need to hire and how they will contribute to your
                                company’s success.
                            </Text>
                        </Column>
                    </ColumnContainer>
                    <BlogBreak space="6px"/>
                </Row>
            </a>
            <a href="/learning-center/hire-for-culture">
                <Row>
                    <ColumnContainer>
                        <Column>
                            <h3>
                                Hiring for Your Culture
                            </h3>
                        </Column>
                        <Column>
                            <Text>
                                Hiring for cultural and team fit is critical to your success. Diversity and inclusion is
                                fundamental to building a great company.
                            </Text>
                        </Column>
                    </ColumnContainer>
                    <BlogBreak space="6px"/>
                </Row>
            </a>
            <a href="/learning-center/find-best-candidates">
                <Row>
                    <ColumnContainer>
                        <Column>
                            <h3>
                                Finding the Best Candidates
                            </h3>
                        </Column>
                        <Column>
                            <Text>
                                Finding top talent is one thing, landing top talent is another. Be prepared to compete
                                for the people you want on your team.
                            </Text>
                        </Column>
                    </ColumnContainer>
                    <BlogBreak space="6px"/>
                </Row>
            </a>
            <a href="/learning-center/compete-for-talent">
                <Row>
                    <ColumnContainer>
                        <Column>
                            <h3>
                                Compete for Top Talent
                            </h3>
                        </Column>
                        <Column>
                            <Text>
                                Finding top talent is one thing, landing top talent is another. Be prepared to compete
                                for the people you want on your team.
                            </Text>
                        </Column>
                    </ColumnContainer>
                    <BlogBreak space="6px"/>
                </Row>
            </a>
        </Indent>
        <a href="/learning-center/hiring-early-stage-startups">
            <Row>
                <ColumnContainer>
                    <Column>
                        <h3>Hiring for Early Stage Startups</h3>
                    </Column>
                    <Column>
                        <Text>Congratulations! You are growing a great new startup. You have raised your pre-seed, seed
                            or A round of funding or landed your first client. Now you need to hire new employees and
                            build your team. Whether you need to hire one person or a team knowing where to start, how
                            to start and who to trust to help you are important factors as you work through these 4
                            stages of hiring.</Text>
                    </Column>
                </ColumnContainer>
                <BlogBreak space="6px"/>
            </Row>
        </a>
        <a href="/learning-center/create-advisory-board">
            <Row>
                <ColumnContainer>
                    <Column>
                        <h3>Create Your Advisory Board</h3>
                    </Column>
                    <Column>
                        <Text>As a startup you need to have a highly valued Advisory Board. The right Board provide
                            insights, expertise, diversity, connections, leadership and a safe harbor for much needed
                            advice. Selecting the right Board members is as important as the people you hire.</Text>
                    </Column>
                </ColumnContainer>
                <BlogBreak space="6px"/>
            </Row>
        </a>
    </div>
);