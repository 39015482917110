/**
 * Created by elwhite on 2/14/20.
 */

import React from 'react';
import style from './button.module.css'


export const Button = ({children, href}) => {
    return (
        <div className={style.buttonContainer}>
        <a href={href} className={style.button}>
            <div className={style.button}>
                <div className={style.buttonText}>
                    {children}
                </div>
            </div>
        </a>
        </div>
    )
};

