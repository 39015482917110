/**
 * Created by elwhite on 2/13/20.
 */
import React from 'react';
import style from "./calender.module.css";



export const Calander = () => {
    return (
        <div className={style.calender}>
            <iframe
                name="calendly"
                id="calendly"
                title="calendly"
                src="https://calendly.com/dwhite-scpa/phone-call-web"
                width="100%"
                height="100%"
                frameBorder="0"
                scrolling="no"
            ></iframe>
        </div>
    );
};

