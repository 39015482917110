// lib imports
import React from 'react';
// custom components
import {
    Row,
    Text,
    Column,
    Spacer,
    Header,

} from '../../Components/Layout/Layout';

import style from '../pages.modules.css';

export const HireCTO = () => (
    <div className={style.page}>
        <Row>
            <Column>
                <Header>Do You Need to Hire a CTO</Header>
                <Spacer space="30px"/>
                <Text>
                    I have heard “I need a CTO” many times from early stage startups. Do you need a CTO or would a well
                    qualified engineer or team lead fit your needs? Often times startups make the decision to hire a CTO
                    in order to pay in equity, saving cash. How you pay does not make the role a CTO. You need to hire
                    the right skills for what you need at this time.                </Text>
                <ol>
                    <li>Determine your true business need</li>
                    <li>
                        Establish what this role does to solve your need: strategist / leader, exceptional developer,
                        or both.
                    </li>
                    <li>Determine if your needs will change in 3 to 9 months and what this role will become over time.</li>
                    <li>Create a job description and title to focus on the right candidate</li>
                </ol>
                <Spacer space="20px"/>
                <Text>
                    When you go through this process you will know whether a CTO or another role best fits your business
                    needs.
                </Text>
                <Spacer space="20px"/>
                <Text>
                    If you have a short-term need consider a contractor. This gives you the chance to work with someone 
                    before making a long-term decision. This costs some cash, a tight commodity for you right now, but 
                    preserves equity. It is painful to award equity only to have that person leave.
                </Text>
                <Spacer space="20px"/>
                <Text>
                    This works for other roles too:
                </Text>
                <ul>
                    <li>VP of Sales / CRO or a senior sales person?</li>
                    <li>VP Marketing / CMO or a senior marketing person?</li>
                    <li>COO or an operations person?</li>
                </ul>
                <Spacer space="20px"/>
                <Text>Building your initial team is critical. Give each hire as much thought as possible.</Text>                
            </Column>
        </Row>
    </div>
);