/**
 * Created by elwhite on 3/28/19.
 */
import React from 'react';
import {
    Row, Header, Text, BoldText, TextColumn, TextColumnContainer,
    ImgSrc, Spacer, Column, ColumnContainer
} from '../Components/Layout/Layout';
import {TitleHeader} from '../Components/Title/Title';
import {DaveCard, EricCard} from '../Components/Contact/Contact';
import tie from '../Components/Imgs/tie-silicon-logo.png';
import chairs from '../Components/Imgs/chairs.svg';
import styles from './pages.modules.css'



export const About = () => (
    <div className={styles.page}>
        <TitleHeader
            sideChildren={<ImgSrc src={chairs}/>}>
            <Header>Relationship Driven Recruiting</Header>
            <Text>With over 20 years of experience serving Silicon Valley startups we have a proven track record of
                bringing in top talent across the board; engineering, product, marketing, sales,support, and operations
                professionals at all levels.</Text>
        </TitleHeader>
        <Row>
            <Column>
                <Header>Mission</Header>
                <Text>Promote long-term growth and success for our clients and their employees by hiring the best people
                    for
                    their business and culture. Enable Silicon Valley startups to meet company goals through
                    personalized
                    hiring strategies and recruiting programs.</Text>
            </Column>
            <Column>
                <Header>Values</Header>
            </Column>
            <ColumnContainer>
                <div style={{display: 'flex', flexDirection: 'column', padding: '10px 1%'}}>
                    <Text>Honesty:</Text>
                    <Text>Diversity:</Text>
                    <Text>Reliability:</Text>
                    <Text>Inclusivity:</Text>
                    <Text>Driven:</Text>
                </div>
                <Column>
                    <Text>Fully transparent and open with clients and candidates</Text>
                    <Text>Strive to create diverse candidate pools and opportunities</Text>
                    <Text> Meet our commitments and present quality candidates</Text>
                    <Text>Everyone has an opportunity, leaving no one out</Text>
                    <Text>Exceed the expectations of our clients and candidates</Text>
                </Column>
            </ColumnContainer>
        </Row>
        <Row>
            <ColumnContainer>
                <DaveCard>
                    <Spacer space="20px"/>
                    <Text>Dave served as President of an executive recruiting firm
                        specializing
                        in high-growth communications and wireless technology companies. Dave has over 30 years of
                        high tech
                        and
                        recruiting experience during which he has been Vice President of Sales and Marketing for two
                        high-technology companies and held several sales and marketing management roles during his
                        fourteen
                        years
                        with Hewlett Packard. Dave holds a BSEE degree from Penn State.</Text>
                </DaveCard>
                <EricCard>
                    <Spacer space="20px"/>
                    <Text>
                        Eric graduated with a B.S. in Mathmatical Economics and then went on to get his M.S. in
                        Computer
                        Science from Santa Clara University. Eric is passionate about helping startups grow and
                        helping
                        people find a career they love.
                    </Text>
                </EricCard>
            </ColumnContainer>
        </Row>
        <Row>
            <TextColumnContainer>
                <TextColumn>
                    <a href="https://sv.tie.org/charter-members-list/" target="_blank" rel="noopener noreferrer">
                        <img src={ tie } alt="" style={{width:"100%", height:"auto"}}/>
                    </a>
                </TextColumn>
                <TextColumn>
                    <BoldText>SC Palo Alto is a proud Charter Member of TiE Silicon Valley</BoldText>
                </TextColumn>
            </TextColumnContainer>
        </Row>
    </div>


);