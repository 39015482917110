// lib imports
import React from 'react';
// custom components
import {
    Row,
    Text,
    Column,
    Spacer,
    Header,

} from '../../Components/Layout/Layout';

import style from '../pages.modules.css';

export const TeamFit = () => (
    <div className={style.page}>
        <Row>
            <Column>
                <Header>Determining a Team Fit</Header>
                <Spacer space="30px"/>
                <Text>
                    You are sitting across the table with a top candidate. She seems great! Friendly. High energy. Lots
                    of eye contact. Great demeanor. Seems very interested and prepared. Easy to talk with. You end the
                    interview feeling great. She seems like a fit. After all, your company is friendly with lots of
                    great teamwork. She might be the one!
                </Text>
                <Spacer space="20px"/>
                <Text>
                    But, did you really get a feel for her culture and team fit?
                </Text>
                <Spacer space="20px"/>
                <Text>
                    A candidate’s personality traits during an interview are good indicators of team fit, but you have
                    to go further. I suggest the following:
                </Text>
                <Spacer space="20px"/>
                <Text>
                    A candidate’s personality traits during an interview are good indicators of team fit, but you have
                    to go further. I suggest the following:
                </Text>
                <Spacer space="20px"/>
                <ol>
                    <li>Truly understand your current team culture and dynamics. Know what you want it to be as your
                        team grows.</li>
                    <li>Develop situational interview questions that directly reflect your real team culture. Know
                        what answers best fit your company. Sample areas can include:</li>
                    <ul>
                        <li>Problem and conflict resolution</li>
                        <li>Risk taking</li>
                        <li>Creativity</li>
                        <li>Encourages diversity and inclusion</li>
                        <li>Ability to speak up</li>
                        <li>Ability to manage stress</li>
                        <li>Fast pace or methodical</li>
                        <li>Work ethic</li>
                    </ul>
                    <li>Have multiple interviewers ask real-life team fit questions. Have them describe true
                        situations.</li>
                    <li>Create scenarios or interactive presentations with multiple interviewers in the room. Look for
                        room dynamics and individual interactions.</li>
                    <li>Describe your culture and look for reactions and ask for their thoughts.</li>
                </ol>
            </Column>
        </Row>
    </div>
);
