/**
 * Created by elwhite on 10/30/20.
 */
import React from 'react';
// custom components
import {
    Row,
    BoldText,
    Text,
    ColumnContainer,
    Column,
    Spacer,
    Header,

} from '../Components/Layout/Layout';

import styles from './pages.modules.css';

export const HiringESS = () => (
    <div className={styles.page}>
    <Row>
        <Column>
            <Header>Hiring for Early Stage Startups</Header>
            <Spacer space="30px"/>
            <Text>You are growing a great new startup. You have raised pre-seed, seed or A round funding or landed your
                first client. Now you need to hire your next employees and grow your team. Where do you start? How do
                you start? How do you know you are finding and hiring the right people for you? As a startup, every
                employee you hire is critical to your success and can propel you ahead or hold you back.</Text>
            <Spacer space="30px"/>
            <Text>There is a winning formula with proven results:</Text>
            <ul>
                <li>Getting started</li>
                <li>Analyze costs and save time</li>
                <li>Develop your plan</li>
                <li>Hire the best employees for you</li>
            </ul>
            <h3>Getting Started</h3>
            <Text>Start with your business goals. Determine:</Text>
            <ul>
                <li>What you need to achieve as a company in the short and long term.</li>
                <li>How each new employee will contribute to meeting your goals.</li>
                <li>The skills and talent you need to hire to support your company and team goals.</li>
            </ul>
            <Spacer space="30px"/>
            <Text>If you do not have one yet, create an organization chart showing the reporting relationships, levels
                of authority and decision making for each team and each employee. The chart should show today’s
                organization as well as your vision for 6 to12 months out to ensure your hiring plans match your goals
                over time.</Text>
            <Spacer space="30px"/>
            <Text>Determine your total budget for each role. This includes the cost for each employee as well as
                costs of recruiting, hiring and onboarding. Employee costs include base pay, bonus or commission,
                benefits, payroll taxes, travel, equipment and work setup, support costs and other costs specific to
                this role. Recruiting costs can be significant and need to be part of your upfront budget. See our
                accounting of recruiting costs in section 2 below.</Text>
            <Spacer space="30px"/>
            <Text>Set a realistic timeframe. Start with the date you
                need your new employee to be fully up to speed and work backwards to determine when you need to start
                actively recruiting. This timeframe can be between 4 and 12 weeks or more based on the role you need to
                fill.</Text>
            <Spacer space="30px"/>
            <Text>Develop job descriptions, candidate profiles and interview processes based on your business goals,
                organization plan and timeframe. This can be quite time consuming,but the more thorough you are now the
                better your results will be going forward.</Text>
            <Spacer space="30px"/>
            <Text>&#x2713; Developing a compelling story and your company’s recruiting brand is essential to attracting
                top performers. They need to see you are an exciting company with a role that resonates with them on a
                pragmatic and emotional level. Answer:</Text>
            <ul>
                <li>What is exciting about what you are doing, your company, your market and your work environment?</li>
                <li>What is exciting about this role and what will they accomplish?</li>
                <li>What you team is like and what is your work environment?</li>
                <li>How can they grow once they are there and how will they grow?</li>
            </ul>
            <h3>Analyze Costs and Save Time</h3>
            <Text>Based on your plan setting a budget is the next important part of moving ahead. Analyze the costs and
                decide what resources you should deploy based on total costsand ROI.</Text>
            <Spacer space="30px"/>
            <Text>We estimate the following:</Text>
            <ColumnContainer>
                <Column>
                    <Text>Job boards such as LinkedIn, Indeed, angel.co</Text>
                </Column>
                <Column>$500 to $1,000 per month per job board</Column>
            </ColumnContainer>
            <ColumnContainer>
                <Column>Social media presence</Column>
                <Column>$100 to $500 per month</Column>
            </ColumnContainer>
            <ColumnContainer>
                <Column>Career Events</Column>
                <Column>0 to $3000 per event</Column>
            </ColumnContainer>
            <ColumnContainer>
                <Column>External Recruiters</Column>
                <Column>20% to 30% of base or total compensation</Column>
            </ColumnContainer>
            <ColumnContainer>
                <Column>Internal Recruiters</Column>
                <Column>$100,000 to $200,000 per year based on experience</Column>
            </ColumnContainer>
            <ColumnContainer>
                <Column>Internal support personnel</Column>
                <Column>$50,000 to $90,000 per year per person</Column>
            </ColumnContainer>
            <Spacer space="16px"/>
            <Text>The hidden cost of missing your goals can be very large. Late product release, missed sales and market
                expansion, lost clients, missed fund raising milestones, lack of speed and other missed goals are
                significant hidden costs of missing your hiring targets. Missed hiring goals can be the result of not
                finding the right candidates on time, a slow interview process, underestimating onboarding time and, in
                the worst case, having to terminate a new employee after months of non-performance, setbacks and missed
                commitments.</Text>
            <Spacer space="30px"/>
            <Text>&#x2713; Pre-seed, seed or early A round funded companies should not have to make a choice between
                paying high recruiting fees or using currently stretched internal resources to find and hire talent on
                their own. A solid plan aligned with the right resources and partners can make a big impact on your
                total cost</Text>
            <Spacer space="30px"/>
            <BoldText>Save Time</BoldText>
            <Text>Hiring your next employee on time moves you closer to meeting your goals and reducing the risk of
                missing important deadlines. Yet, you need to balance this with ensuring you are hiring the best person
                possible.</Text>
            <Spacer space="30px"/>
            <Text>A look at the time to hire:</Text>
            <ul>
                <li>Once you have started a search it takes 4 to 12 weeks, based on the type and level of the role, to
                    make an offer.
                </li>
                <li>It typically takes 2 weeks from an accepted offer to when your new employeestarts. This could be
                    longer if your new employee needs more time at their current employer, need to relocate, or needs
                    visa sponsorship.
                </li>
                <li>Consider the time it will take for a new employee to come up to speed and befully productive once
                    they start.
                </li>
            </ul>
            <Spacer space="30px"/>
            <Text>&#x2713; Plan on devoting time every day to your search. Based on the types of roles and numbers of
                positions you need to fill you may need to spend 1 to 4 hours a day untilthe search is
                completed. </Text>
            <Spacer space="30px"/>
            <Text>&#x2713; Working with a trusted partner will reduce the time it takes to hire the right person and
                reduces the time you and your time will need to spend every day on your searches.</Text>
            <h3>Develop Your Plan</h3>
            <Text>Developing a detailed search plan is essential to successful hiring and will help you avoid an
                unsuccessful search after months of work or hiring the wrong employee.</Text>
            <Spacer space="30px"/>
            <Text>Your search plan is based on your business and team goals, budget and timeframe including the
                following:</Text>
            <ul>
                <li>Search process to ensure you find the top candidates:
                    <ul>
                        <li>Communicate internally who you need to hire and why</li>
                        <li>Communicate with your network and expanding your reach</li>
                        <li>Expand word of mouth and referrals</li>
                        <li>Post on job boards and social media</li>
                        <li>Examine and deploy external resources</li>
                    </ul>
                </li>
                <li>Candidate evaluation process</li>
                <li>Candidate tracking system</li>
                <li>Communication process for all candidates</li>
                <li>Offer process</li>
                <li>Onboarding process</li>
                <li>Metrics and progress tracking system</li>
            </ul>
            <Spacer space="30px"/>
            <Text>&#x2713; Working with a trusted partner will work with you to develop your plan and all of
                theprocesses that make up your pan. They will also manage the plan throughout your search to ensure
                successful hires</Text>
            <h3>Hire the best Employees</h3>
            <Text>Now, it is time to pull it all together and put your plans in action. You are set up for success and
                well on your way to finding and hiring your new employees. In takes time every day. Follow you plan,
                track your progress and make adjustments as you go. </Text>
            <ul>
                <li>Schedule time every day for recruiting and reaching out to candidates and your network</li>
                <li>Make adjustments to your plan as you go</li>
                <li>Track all of your activity daily</li>
                <li>Respond to candidates and applicants quickly, timing is important with your top candidates since
                    they will have options
                </li>
                <li>Manage interviews closely, make sure they run smoothly and on time</li>
                <li>Gather and provide feedback rapidly</li>
                <li>Once an offer is accepted talk with your new employee several times a week before they start</li>
            </ul>
            <Spacer space="30px"/>
            <Text>&#x2713; Once you have identified your top candidate and are ready to make an offer</Text>
            <Text>&#x2713; Check out our <a href="/learning-center">Learning Center</a> for more important information
                to boost your hiring effectiveness and efficiency.</Text>
        </Column>
    </Row>
    </div>
);