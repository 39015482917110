/**
 * Created by elwhite on 10/30/20.
 */
import React from 'react';
// custom components
import {
    Row,
    BoldText,
    Text,
    Column,
    Spacer,
    Header,

} from '../Components/Layout/Layout';

import styles from './pages.modules.css';

export const CompeteForTalent = () => (
    <div className={styles.page}>
        <Row>
            <Column>
                <Header>Compete for Top Talent</Header>
                <Spacer space="30px"/>
                <Text>Finding top talent is one thing, competing for top talent is another. Not every qualified
                    candidate will be the right fit for you or see your opportunity as the right fitfor them.</Text>
                <Spacer space="30px"/>
                <Text>If your company is a startup you are competing against large companies with potentially higher
                    pay, higher brand recognition and probably better benefits. You are also competing with other
                    startups looking for the same talent. And, some top candidates may be considering starting their own
                    company. It is a competitive landscape.</Text>
                <Spacer space="30px"/>
                <Text>The key is to know why you are the right place to work specifically for the people you want to
                    hire. You do not need to be the right fit for everyone.</Text>
                <Spacer space="30px"/>
                <Text>The first step is to develop and communicate your “Recruiting Brand”. Like your market brand, this
                    is the winning message you want broadcast to potential candidates. It is the story of working for
                    your company and why it is the right place to work. Your brand includes your goals, team, funding,
                    marketplace, achievements and recognition, culture, and work environment. Allow people to see and
                    feel your company and culture. Have top candidates meet with your CEO and the executive team to see
                    who is leading the company.</Text>
                <Spacer space="30px"/>
                <BoldText>Important Realizations: </BoldText>
                <ul>
                    <li>It is really about the role and the impact the candidate will have on the company and team
                        success. Be able to describe how this role impacts the company and the team in tangible terms.
                    </li>
                    <li>Offering opportunities to learn and grow on their career is a critical part of a candidate’s
                        decision. Seeing a growth path and opportunities to assume larger responsibilities can be a
                        deciding factor.
                    </li>
                    <li>Being valued is a key component to employee satisfaction. Be able to articulate the value they
                        will have to the company and the people on their team.
                    </li>
                    <li>Your company mission is important. People want to work for a company with an inspiring purpose
                        and a mission.
                    </li>
                    <li>Your culture and having a diverse and inclusive company is important. A candidate needs to see
                        that they are a match for your culture and that they will fit in with the team. Having a diverse
                        and inclusive culture shows people
                        you care about building a complete and well-rounded team that embraces different points of view.
                    </li>
                    <li>It is not all about the money, but compensation is important. Base pay, bonusand commission,
                        stock options and grants, and tangible benefits all count. Within reason, many people will be
                        flexible about compensation for the right company, role, and situation. It is all about the
                        balance. Know the market andknow how you compare.
                    </li>
                    <li>For most people your leadership is critical in making their decision. This includes your CEO,
                        founders, executive team and managers. What is their style? Have they done this before? Do they
                        have a clear vision of where the company is headed?
                    </li>
                    <li>It is not all about the money, but compensation is important. Base pay, bonusand commission,
                        stock options and grants, and tangible benefits all count. Within reason, many people will be
                        flexible about compensation for the right company, role, and situation. It is all about the
                        balance. Know the market andknow how you compare.
                    </li>
                    <li>Mechanical items are important. These include flexible work hours, commute times, work from home
                        options, work authorization, travel and the like. Theseshould be addressed early in the process
                        to make sure there are no blockers to accepting your offer.
                    </li>
                </ul>
                <Spacer space="30px"/>
                <Text>Start your conversations with every candidate seeking to understand what is most important for
                    them in evaluating their next career move. This will allow you to develop a plan that addresses
                    their most important criteria as you move through the process. If you cannot address important
                    factors for your candidate discuss these early to avoid a long and unsuccessful process</Text>
                <Spacer space="30px"/>
                <Text>Be prepared to compete on all of these factors to land the people you want to hire.</Text>
            </Column>
        </Row>
    </div>
);